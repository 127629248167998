/* eslint-disable react/no-unescaped-entities */
import getYear from "date-fns/getYear";

export default function Disclaimers() {
  const copyright = <span>© {getYear(new Date())}</span>;
  const finchat = (
    <a
      href="https://finchat.io/"
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 underline underline-offset-2"
    >
      Finchat ("Stratosphere Technology Inc")
    </a>
  );

  return (
    <section className="mx-auto grid max-w-screen-legacyDesktop justify-center px-4 text-center xl:w-full xl:px-0">
      <p className="m-0 text-sm font-normal leading-loose tracking-widest text-slate-500 lg:text-left lg:leading-relaxed">
        Disclaimers: {copyright} Morningstar - Os dados fundamentalistas e cotações de empresas Americanas ("Stocks")
        são fornecidos pela Morningstar, atualizados diariamente. KPIs de empresas Americanas ("Stocks") são fornecidos
        pelo {finchat}, Cotações de ativos Brasileiros são fornecidos pela {copyright} Enfoque Informações Financeiras
        LTDA, atualizados diariamente. Dados de moedas (Forex) são fornecidos pela {copyright} apilayer Data Products
        GmbH ("Currencylayer"), atualizados diariamente. Outros dados embutidos nas páginas das empresas tem origem
        direta dos sistemas dos órgãos regulatórios como CVM ("Comissão de Valores Mobiliários") / SEC ("U.S. Securities
        and Exchange Commission"), como também podem ser obtidos de comunicados oficiais das empresas em questão. O
        Fundamentei não se responsabiliza pela precisão/completude ou integridade das informações apresentadas no site
        ou em qualquer um de seus produtos. O Fundamentei também não se responsabiliza por quaisquer danos ou prejuízos
        decorrentes do uso dessas informações.
      </p>
    </section>
  );
}
