import gql from "graphql-tag";

export default gql`
  mutation AddIssuerToBookmarks($issuerId: ObjectID!) {
    addIssuerToBookmarks(issuerId: $issuerId) {
      ... on AmericanCompanyIssuer {
        _id
        viewerDidBookmark
      }
      ... on BrazilianCompanyIssuer {
        _id
        viewerDidBookmark
      }
      ... on BrazilianRealEstateFund {
        _id
        viewerDidBookmark
      }
      ... on BrazilianRealEstateFundIssuer {
        _id
        viewerDidBookmark
      }
    }
  }
`;
